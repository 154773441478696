import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const OurHistory = () => {
    const seo = {
        metaDesc: 'Our History - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout activeMenu={'about'}>
            <SEO title="Our History - Inventiv.org" canonical='/our-history' seo={seo} />
            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>Our History</h1>
                    </div>
                </div>
            </div>

            <section class="pt-3 pb-3 mt-3 mb-3">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <img src="assets/img/banner/working-team.webp" width="100%" alt="" />
                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <p class="f-16 sec-clr">
                                Inventiv was born out of a shared passion for invention and a deep belief in the power of human creativity. Our journey began several years ago when a group of visionary inventors and innovators came together with a common goal: to create a global platform that would inspire, empower, and connect inventors from all walks of life.
                                <br /><br />
                                Founded in 2018, Inventiv started as a small community of like-minded individuals driven by a collective vision. We recognized that inventors hold the key to solving some of the world’s most pressing challenges, and we were determined to create an environment where their ideas could flourish.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-3 pb-3 mt-3 mb-3 bg-light-blue our-history-sec-2">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-8 wow fadeInLeft delay-04s">
                            <p class="f-16 sec-clr">
                                From the outset, we were committed to nurturing the inventors of tomorrow. We started by providing educational programs, workshops, and mentorship opportunities to aspiring inventors, fueling their curiosity and guiding them along their inventive journeys. We believed that by equipping inventors with the necessary skills and knowledge, we could unlock their full potential.
                                <br /><br />
                                As our community grew, so did our impact. We expanded our reach to include underserved communities, partnering with organizations and institutions to bring inventive resources and opportunities to those who needed them most. We celebrated diversity and recognized that innovation knows no boundaries, transcending geographical, cultural, and socioeconomic barriers.
                                <br /><br />
                                Over the years, Inventiv has evolved into a global movement that harnesses the collective genius of inventors and supports their inventive endeavors. We have built a vibrant ecosystem of inventors, mentors, experts, and partners who collaborate, learn, and create together. Through our collaborative spaces, funding programs, and global initiatives, we continue to provide inventors with the support they need to turn their ideas into reality.
                                <br /><br />
                                Today, Inventiv stands at the forefront of the invention and innovation landscape. Our community of inventors spans the globe, and their inventions are making a meaningful impact in fields such as healthcare, sustainability, technology, and beyond. We are proud of the inventors who have emerged from our community, and we are inspired by their dedication to creating a better world.
                            </p>
                        </div>
                        <div class="col-12 col-md-4 wow fadeInRight delay-04s">
                            <div class="img-1 text-center mb-1">
                                <img src="assets/img/banner/working-together-people.webp" width="100%" alt="" />
                            </div>
                            <div class="img-2 text-center mb-1">
                                <img src="assets/img/banner/smile-on-their-faces.webp" width="90%" alt="" />
                            </div>
                            <div class="img-3 text-center mb-1">
                                <img src="assets/img/banner/business-meeting-with-client.webp" width="90%" alt="" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-3 pb-3 mt-3 mb-3">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <img src="assets/img/banner/three-people-working-together.webp" width="100%" alt="" />

                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <p class="f-16 sec-clr">
                                As we look to the future, our commitment to empowering inventors remains unwavering. We will continue to adapt and grow, ensuring that Inventiv remains a catalyst for innovation and a platform for inventors to thrive. We will foster collaborations, advocate for inventors’ rights, and push the boundaries of what’s possible.
                                <br /><br />
                                Join us at Inventiv and be a part of our ever-evolving history. Together, let’s unleash the power of invention and shape a future where creativity and innovation know no limits.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default OurHistory;